import React from 'react';
import styles from './index.module.less';
import { Button, Modal, Result } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { useTranslation } from 'next-i18next';

const LoginResult = () => {
  const { t } = useTranslation('common');

  const {
    openLoginResult,
    onChangedLoginResult,
  } = useUserProvider();

  return (
    <>
      <Modal
        className={styles.mainContainer}
        open={openLoginResult}
        onCancel={() => onChangedLoginResult(false)}
        destroyOnClose={true}
      >
        <div className={styles.homeContent} style={{ padding: '20px 24px' }}>
          <Result
            status="success"
            title={t('LoggedInSuccessfully')}
            // subTitle={t('InTheFutureYouCanVerifyThroughEmailCode_Desc')}
            extra={[
              <Button
                type="primary"
                key="console"
                className={styles.loginFormButton}
                onClick={() => {
                  onChangedLoginResult(false);
                }}
              >
                {t('Continue')}
              </Button>,
            ]}
          />
        </div>
      </Modal>
    </>
  );
};

export default LoginResult;
